import React, { useRef } from 'react';
import { Card, CardContent, Checkbox, CardHeader, Box, Button, FormControl, InputLabel, MenuItem, Select, Autocomplete, TextField, FormHelperText, FormLabel, RadioGroup, FormControlLabel, Radio, Typography } from '@mui/material'
import { useState, useEffect, useCallback } from 'react';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import MEDrawer from '../../components/MEDrawer';
import Toast from '../../components/Toast';
import useAxios from '../../hooks/useAxios';
import useLocalStorage from '../../hooks/useLocalStorage';
import useToast from '../../hooks/useToast';
import Avatar from '@mui/material/Avatar';
import ListIcon from '@mui/icons-material/List';
import { blue, green, grey } from '@mui/material/colors';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ReceiptIcon from '@mui/icons-material/Receipt';

import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';
import useIsMobile from '../../hooks/useIsMobile';

const todayDate = moment().format("YYYY-MM-DD");

const PaymentReceipt = () => {

  const { t } = useTranslation();

  // const partyListRef = useRef();
  const descriptionRef = useRef(null);
  const dateFilterRef = useRef(null);
  const isMobile = useIsMobile();

  const colSet1 = [
    { field: 'transactionDetailID', headerName: t('#'), width: 50, renderCell: (index) => index.api.getRowIndex(index.row.transactionDetailID) + 1 },
    { field: 'transactionDate', headerName: t('date'), width: 120 },
    { field: 'subHeadName', headerName: t('account'), width: 200 },
    { field: 'description', headerName: t('description'), width: 500, valueGetter: (params) => `${params.row.description}` },
    { field: 'exchangeRate', headerName: t('rate'), width: 150 },
  ];

  const colSet2 = [
    // { field: 'transactionType', headerName: t('transaction_type'), width: 150 },
    // { field: 'transactionTypeNumber', headerName: t('ref_no'), width: 300 },
    // { field: 'customerName', headerName: t('customer_name'), width: 400 },
    {
      field: 'Action', headerName: t('action'), width: 200, type: 'actions', getActions: (params, GridRowParams) => [
        <GridActionsCellItem icon={<DeleteIcon />} onClick={handleDelete(params.row.transactionID)} label={t('delete')} />,
        <GridActionsCellItem icon={<EditIcon />} onClick={handleEdit(params.row.transactionID, params.row.isEditable)} label={t('edit')} />
      ]
    }
  ];

  const initialState = {
    partyTypeID: 1,
    conversion: 0,
    transactionDate: todayDate,
    receivingDate: '',
    isCredit: 1,
    isBankCredit: 1,
    transactionTypeID: 1,
    paymentSource: 'party',
    partyID: null,
    partyNo: '',
    referralPartyID: null,
    referralPartyNo: '',
    currencyID: '1',
    exchangeRate: '1',
    isCashBank: false,
    cashAmount: '',
    chequeBankAccountID: '',
    chequeNumber: '',
    chequeDate: todayDate,
    chequeAmount: '',
    bankAccountID: '',
    bankAmount: '',
    bankTransactionID: '',
    description: '',
    description2: '',
    transactionTypeNumber: '',
    customerName: '',
    customerDescription: '',
    refNo: '',
    isBuySellWalkinCustomer: false
  };

  const [formValues, setFormValues] = useState(initialState);
  const [selectedParty, setSelectedParty] = useState(null);
  const [selectedReferralParty, setSelectedReferralParty] = useState(null);
  const [partyTypeID, setPartyTypeID] = useState(1);
  const [bankBalance, setBankBalance] = useState(0);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [transactionData, setTransactionData] = useState([]);
  const [buttonText, setButtonText] = useState('save');
  const [editID, setEditID] = useState(0);
  const [isRequesting, setIsRequesting] = useState(false);
  const [partyTypes, setPartyTypes] = useState([]);
  const [partyList, setPartyList] = useState([]);
  const [regularPartyList, setRegularPartyList] = useState([]);
  const [partyCopyList, setPartyCopyList] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [referralPartyCurrencies, setReferralPartyCurrencies] = useState([]);
  const [cashBalances, setCashBalances] = useState({});
  const [bankAccounts, setBankAccounts] = useState([]);
  const [columns, setColumns] = useState([]);
  const [transactionDateFilter, setTransactionDateFilter] = useState(todayDate);

  const [listError, sendListRequest] = useAxios(setData);
  const [bankAccountsListError, sendBankAccountsRequest] = useAxios(setBankAccountsData);
  const [editError, sendEditRequest] = useAxios(setEditData);
  const [deleteError, sendDeleteRequest] = useAxios(checkDeleted);
  const [partyError, sendPartyList] = useAxios(setPartyInfo);
  const [partyBalanceError, getPartyBalance] = useAxios(setPartyBalanceInfo);
  const [referralPartyBalanceError, getReferralPartyBalance] = useAxios(setReferralPartyBalanceInfo);
  const [bankBalanceError, getBankBalance] = useAxios(setBankBalanceInfo);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const [error, sendPostRequest] = useAxios(getSavedData);

  const [user, setUser] = useLocalStorage("user", null);

  const { open, setOpen, responseType, setResponseType, responseMessage, setResponseMessage, handleClose } = useToast();

  const handleTransactionTypeChange = (event) => {
    setFormValues({ ...formValues, transactionTypeID: event.target.value });
    // if ([1, 2, 3].includes(parseInt(event.target.value))) {
    //   fetchParties(1);
    // }
  }

  const handleIsCashBankChanged = (event) => {
    setFormValues({ ...formValues, isCashBank: event.target.checked });
  }

  const handlePaymentSourceChange = (event) => {
    setFormValues({ ...formValues, paymentSource: event.target.value });
  }

  const transactionDateChanged = (event) => {
    setTransactionDateFilter(event.target.value);
  }

  const handlePartyTypeChanged = (event) => {
    setPartyTypeID(event.target.value);
    // fetchParties(event.target.value);
  }

  useEffect(() => {
    fetchParties(partyTypeID);
  }, [partyTypeID])

  useEffect(() => {
    fetchListData();
  }, [transactionDateFilter])

  function setData(data) {
    setTransactionData(data.data.ledger);
    setPartyTypes(data.data.partyTypes);
    setCurrencies(data.data.currencies);
    setReferralPartyCurrencies(data.data.currencies);
    setCashBalances(data.data.cashBalances);
    var currenciesData = data.data.currencies;
    var aryCurrencies = [];
    currenciesData.map(currency => {
      aryCurrencies.push({ field: 'credit' + currency.currencyCode, 'headerName': t('credit') + ' (' + t(currency.currencyCode) + ')', width: 150, valueGetter: (params) => `${new Intl.NumberFormat('en-IR').format(params.row.currency[currency.currencyCode].credit)}` });
      aryCurrencies.push({ field: 'debit' + currency.currencyCode, 'headerName': t('debit') + ' (' + t(currency.currencyCode) + ')', width: 150, valueGetter: (params) => `${new Intl.NumberFormat('en-IR').format(params.row.currency[currency.currencyCode].debit)}` });
      aryCurrencies.push({ field: 'balance' + currency.currencyCode, 'headerName': t('balance') + ' (' + t(currency.currencyCode) + ')', width: 150, valueGetter: (params) => `${new Intl.NumberFormat('en-IR').format(params.row.currency[currency.currencyCode].balance)}` });
      // aryCurrencies.push({ field: 'balance' + currency.currencyCode, 'headerName': t('balance') + ' (' + t(currency.currencyCode) + ')', width: 150, valueGetter: (params) => `${new Intl.NumberFormat('en-IR').format(params.row[currency.currencyCode])}` });
    });
    setColumns([...colSet1, ...aryCurrencies, ...colSet2]);
  }

  function setBankAccountsData(data) {
    setBankAccounts(data.data.bankAccount);
  }

  const handleKeyUp = (e, targetElem) => {
    if (e.key === 'Enter') {
      descriptionRef.current.focus();
    }
  }

  function setEditData(data) {
    console.log(data.data);
    var editData = data.data.transactionData;
    setPartyTypeID(editData.partyTypeID);
    setFormValues(editData);
    if (editData.partyID === null) {
      setSelectedParty(null);
    } else {
      setSelectedParty({ partyID: editData.partyID, address: editData.partyAddress, partyName: editData.partyName });
    }
    if (editData.referralPartyID === null) {
      setSelectedReferralParty(null);
    } else {
      setSelectedReferralParty({partyID: editData.referralPartyID, address: editData.referralPartyAddress, partyName: editData.referralPartyName});
    }

    setIsRequesting(false);
  }

  function setPartyInfo(data) {
    const parties = data.data.partyType.parties;
    var thisPartyTypeID = formValues.partyTypeID;
    if (parties.length && parties[0].partyTypeID == 1 && regularPartyList.length == 0) {
      setRegularPartyList(parties);
    }
    if (parties.length) {
      console.log(parties[0].partyTypeID);
      thisPartyTypeID = parties[0].partyTypeID;
    }
    setPartyList(parties);
    setPartyCopyList(parties);
    setFormValues({ ...formValues, partyNo: data.data.nextHNo, partyTypeID: thisPartyTypeID });
  }

  function setPartyBalanceInfo(data) {
    changeCurrencies(data.data.currenciesPayable);
  }

  function setReferralPartyBalanceInfo(data) {
    changeReferralCurrencies(data.data.currenciesPayable);
  }

  function setBankBalanceInfo(data) {
    // setFormValues({ ...formValues, bankAccountID: data.data.bankAccountID });
    setBankBalance(data.data.balancePKR);
  }

  function changeReferralCurrencies(partyBalance) {
    // console.log(partyBalance);
    var tempReferralCurrencies = [];
    referralPartyCurrencies.map((c) => {
      if (partyBalance) {
        c['refCurrencyPayable'] = partyBalance[c.currencyCode].totalPayable;
      } else {
        c['refCurrencyPayable'] = 0;
      }
      tempReferralCurrencies.push(c);
    });

    setReferralPartyCurrencies(tempReferralCurrencies);
  }

  function changeCurrencies(partyBalance) {
    var tempCurrencies = [];
    currencies.map((c) => {
      if (partyBalance) {
        c['currencyPayable'] = partyBalance[c.currencyCode].totalPayable;
      } else {
        c['currencyPayable'] = 0;
      }
      tempCurrencies.push(c);
    });

    setCurrencies(tempCurrencies);
  }

  function checkDeleted(data) {
    setTransactionDateFilter(dateFilterRef.current.value);
    if (data.data.success === true) {
      setResponseType('success');
      setResponseMessage(data.data.message);
    } else {
      setResponseType('error');
      setResponseMessage(data.message);
    }
    setOpen(true);
    fetchListData(dateFilterRef.current.value);
    setIsRequesting(false);
  }

  const validate = (values) => {
    const errors = {};
    if (parseInt(formValues.transactionTypeID) === 1 && formValues.isCashBank === false) {
      if (isNaN(parseInt(partyTypeID))) {
        errors.partyTypeID = t('party_type_id') + ' ' + t('is_required');
      }
    } else if (parseInt(formValues.transactionTypeID) === 2) {
      // if (isNaN(parseInt(values.receiptFrom))) {
      //   errors.receiptFrom = t('receipt') + ' ' + t('is_required');
      // }
    }
    if (isNaN(parseInt(values.partyID)) && !formValues.isBuySellWalkinCustomer && formValues.isCashBank === false) {
      errors.partyID = t('party') + ' ' + t('is_required');
    }
    if (formValues.transactionTypeNumber !== '') {
      if (values.customerName === '') {
        errors.customerName = t('customer') + ' ' + t('is_required');
      }
    }

    return errors;
  }

  function getSavedData(data) {
    if (data.status === 200) {
      setResponseType('success');
      if (!data.data.success) {
        setResponseType('error');
      }

      setResponseMessage(data.data.message);
      if (responseType === 'success') {
        setFormValues(initialState);
        setSelectedParty(null);
        setSelectedReferralParty(null);
      } else {
        setFormValues(formValues);
      }
    } else {
      setResponseType('error');
      setResponseMessage(data.data.message);
    }
    setOpen(true);
    fetchListData();
    setButtonText('save');
    setEditID(0);
    setIsRequesting(false);
    // window.location.reload(true);
  }

  const handleEdit = useCallback(
    (id, isEditable) => () => {
      // navigate(`/currency/${id}`);
      if (isEditable === 0) {
        alert('This record cannot be edited!');
        return;
      }
      if (isRequesting) return;
      setIsRequesting(true);
      setButtonText('update');
      setEditID(id);
      sendEditRequest({
        url: '/accountHead/transaction/' + id,
        method: 'GET',
        body: null,
        headers: { Accept: 'application/json', Authorization: 'Bearer ' + user.access_token }
      });
    }, []
  );

  const handleDelete = useCallback(
    (id) => () => {
      if (window.confirm('Are you sure you want to delete this record?')) {
        if (isRequesting) return;
        setIsRequesting(true);
        sendDeleteRequest({
          url: '/accountHead/transaction/delete?' + id,
          method: 'DELETE',
          body: {},
          headers: { Accept: 'application/json', Authorization: 'Bearer ' + user.access_token }
        });
      }
    }, []
  );


  const handleParty2Change = (event, party) => {
    if (party === null) {
      setFormValues({ ...formValues, referralPartyID: null });
      setSelectedReferralParty(null);
      return;
    }
    getReferralPartyBalance({
      url: '/party/' + party.partyID + '/balance',
      method: 'GET',
      body: {},
      headers: { Accept: 'application/json', Authorization: 'Bearer ' + user.access_token }
    });
    setFormValues({ ...formValues, referralPartyID: party.partyID });
    setSelectedReferralParty({ partyID: party.partyID, address: party.address, partyName: party.partyName });
  }

  const handlePartyChange = (event, party) => {
    if (party === null) {
      setFormValues({ ...formValues, partyID: null });
      setSelectedParty(null);
      return;
    }
    getPartyBalance({
      url: '/party/' + party.partyID + '/balance',
      method: 'GET',
      body: {},
      headers: { Accept: 'application/json', Authorization: 'Bearer ' + user.access_token }
    });
    setFormValues({ ...formValues, partyID: party.partyID });
    setSelectedParty({ partyID: party.partyID, address: party.address, partyName: party.partyName });
  }

  const aryAmountFields = ['cashAmount', 'chequeAmount', 'bankAmount'];

  const handleChange = (event) => {
    console.log(formValues);
    var { name, value } = event.target;
    if (aryAmountFields.indexOf(name) > -1) {
      if (value !== '-' && value !== '') {
        value = new Intl.NumberFormat('en-IR').format(value.replaceAll(',', ''));
      }
    }

    setFormValues({ ...formValues, [name]: value });

    switch (name) {
      case 'bankAccountID':
        fetchBankBalance(value);
        break;
      default:
        break;
    }
  }

  function fetchBankBalance(bankAccountID) {
    getBankBalance({
      url: '/bankAccount/' + bankAccountID + '/balance',
      method: 'GET',
      body: {},
      headers: { Accept: 'application/json', Authorization: 'Bearer ' + user.access_token }
    });
  }


  function fetchParties(partyTypeID) {
    changeCurrencies();
    sendPartyList({
      url: '/partyType/parties/' + partyTypeID,
      method: 'GET',
      body: {},
      headers: { Accept: 'application/json', Authorization: 'Bearer ' + user.access_token }
    });
  }

  const handleRefKeyPress = (event) => {
    setFormValues({ ...formValues, refNo: event.target.value });
    console.log(event.target.value);
    if (event.target.value) {
      setPartyCopyList(partyList.filter((party) => party.refNo === event.target.value));
    } else {
      setPartyCopyList(partyList);
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
  };

  const handleCancel = () => {
    setFormValues(initialState);
    setButtonText('Save');
    setEditID(0);
    setIsSubmit(false);
    setFormErrors({});
  }

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      if (isRequesting) return;
      setIsRequesting(true);
      if (!isNaN(parseInt(editID)) && editID > 0) {
        sendPostRequest({
          url: '/party/' + editID,
          method: 'PUT',
          body: formValues,
          headers: { Accept: 'application/json', Authorization: 'Bearer ' + user.access_token }
        });
      } else {
        sendPostRequest({
          url: '/accountHead/doPaymentReceipt',
          method: 'POST',
          body: formValues,
          headers: { Accept: 'application/json', Authorization: 'Bearer ' + user.access_token }
        });
      }
    }
  }, [formErrors]);

  const handleKey = (event) => {
    const { name } = event.target;
    if (event.code === 'KeyC' || event.code === 'KeyD') {
      if (event.code === 'KeyC') {
        setFormValues({ ...formValues, [name]: 1 });
      } else {
        setFormValues({ ...formValues, [name]: 0 });
      }
    }
  };


  useEffect(() => {
    fetchListData();
    fetchBankAccounts();
    fetchParties(partyTypeID);
  }, []);

  useEffect(() => {
    const filteredCurrency = currencies?.filter((currency) => currency.currencyID === formValues.currencyID);
    var convert = 0;
    const myCashAmount = formValues.cashAmount.toString().replaceAll(',', '');
    if (filteredCurrency.length) {
      if (!isNaN(myCashAmount) && formValues.exchangeRate > 0) {
        convert = filteredCurrency[0].convertToPKROperator === '/' ? (myCashAmount / formValues.exchangeRate) : (myCashAmount * formValues.exchangeRate);
      }
    }
    setFormValues({ ...formValues, 'conversion': 'Rs. ' + new Intl.NumberFormat('en-IR').format(convert.toFixed(0) * -1) });
  }, [formValues.cashAmount, formValues.exchangeRate, formValues.currencyID]);

  function fetchBankAccounts() {
    sendBankAccountsRequest({
      url: '/bankAccount',
      method: 'GET',
      body: {},
      headers: { Accept: 'application/json', Authorization: 'Bearer ' + user.access_token }
    })
  }

  function fetchListData(transactionDateFiltered = null) {
    // url: '/accountHead/index?transactionDate=' + transactionDateFilter,
    if (transactionDateFiltered === null) {
      transactionDateFiltered = transactionDateFilter;
    }
    sendListRequest({
      url: `/accountHead/allAccountsBalance?reportDate=${transactionDateFiltered}&reportType=all`,
      method: 'GET',
      body: {},
      headers: { Accept: 'application/json', Authorization: 'Bearer ' + user.access_token }
    })
  }

  return (
    <MEDrawer>
      <Box>
        <Toast open={open} handleClose={handleClose} responseType={responseType} responseMessage={responseMessage} />
        <div>
          <Card sx={{ bgcolor: blue[50] }}>
            <CardHeader avatar={<Avatar sx={{ bgcolor: green[500] }}><ReceiptIcon /></Avatar>} title={t('payment') + ' / ' + t('receipt') + ' ' + t('form')} />
            <CardContent>
              <FormControl sx={{ mb: 2, ml: 1 }} fullWidth>
                <FormLabel></FormLabel>
                <RadioGroup row name="transactionTypeID" value={formValues.transactionTypeID} onChange={handleTransactionTypeChange}>
                  <FormControlLabel value="1" control={<Radio />} label={t('payments_receipts')} />
                  <FormControlLabel value="2" control={<Radio />} label={t('cash')} />
                  <FormControlLabel value="3" control={<Radio />} label={t('buy_sell')} />
                </RadioGroup>
              </FormControl>
              {
                parseInt(formValues.transactionTypeID) === 1 && (
                  <>
                    <br />
                    <RadioGroup sx={{ ml: 1 }} row name="paymentSource" value={formValues.paymentSource} onChange={handlePaymentSourceChange}>
                      <FormControlLabel value="party" control={<Radio />} label={t('party')} />
                      <FormControlLabel value="referral_customer" control={<Radio />} label={t('referral_customer')} />
                      <FormControlLabel value="bank" control={<Radio />} label={t('bank')} />
                    </RadioGroup>
                    <br />
                    <br />
                  </>
                )
              }

              {
                parseInt(formValues.transactionTypeID) === 2 && (
                  <>
                    <br />
                    <FormControlLabel sx={{ mb: 1, ml: 1 }} control={<Checkbox checked={formValues.isCashBank} onChange={handleIsCashBankChanged} />} label="Submit / Withdraw from Bank?" />
                    <br />
                  </>
                )
              }

              {
                (
                  (parseInt(formValues.transactionTypeID) === 1) || (parseInt(formValues.transactionTypeID) === 2 && formValues.isCashBank === false)
                ) ? (
                  <FormControl size="small" sx={{ ml: 1, minWidth: 200 }} error={formErrors?.partyTypeID}>
                    <InputLabel> {t('party_type')}: </InputLabel>
                    <Select label={t('party_type')} onChange={handlePartyTypeChanged} name="partyTypeID" value={partyTypeID}>
                      {
                        partyTypes?.map((value) => {
                          return (<MenuItem key={value.partyTypeID} value={value.partyTypeID}>{value.partyTypeName} </MenuItem>)
                        })
                      }
                    </Select>
                    {/* <FormHelperText>{formErrors.partyTypeID}</FormHelperText> */}
                  </FormControl>
                ) : ('')
              }
              {
                ([1, 2].includes(parseInt(formValues.transactionTypeID)) && parseInt(partyTypeID) === 2 && formValues.isCashBank === false) ?
                  <TextField sx={{ ml: 1 }} helperText={formErrors.refNo} name="refNo" variant="outlined" label={t('ref_no')} value={formValues.refNo} onChange={handleRefKeyPress} size="small" />
                  : ''
              }

              {
                (
                  (parseInt(formValues.transactionTypeID) === 3 && formValues.isBuySellWalkinCustomer === false)
                  ||
                  (parseInt(formValues.transactionTypeID) === 2 && formValues.isCashBank === false)
                  ||
                  (parseInt(formValues.transactionTypeID) === 1)
                ) && (
                  <FormControl size="small" sx={{ ml: 1, minWidth: 200 }} error={formErrors?.partyID && formErrors.partyID.length > 0}>
                    {/* <InputLabel> { t('party') }: </InputLabel> */}
                    <Autocomplete
                      onKeyUp={(e) => handleKeyUp(e)}
                      options={partyCopyList}
                      value={selectedParty}
                      openOnFocus
                      isOptionEqualToValue={(op, va) => op.partyID === va.partyID}
                      getOptionLabel={(option) => (option.partyName + ' ( ' + option.address + ') ')}
                      onChange={handlePartyChange}
                      renderInput={(params) => <TextField {...params} label={t('party')} variant="outlined" />}
                    />
                    <FormHelperText>{formErrors.partyID}</FormHelperText>
                  </FormControl>
                )
              }

              {
                (
                  (parseInt(formValues.transactionTypeID) === 3 && formValues.isBuySellWalkinCustomer === false)
                  ||
                  (parseInt(formValues.transactionTypeID) === 2 && formValues.isCashBank === false)
                  ||
                  (parseInt(formValues.transactionTypeID) === 1)
                ) && currencies?.map((value) => {
                  return (
                    <TextField
                      size="small"
                      InputProps={{
                        readOnly: true,
                      }}
                      sx={{ ml: 1, maxWidth: 150 }}
                      key={value.currencyID}
                      id={"currency_" + value.currencyID}
                      label={value.currencyName}
                      value={value.currencyPayable || '0'}
                    />
                  )
                })
              }

              <TextField type="date" sx={{ ml: 1, minWidth: 200 }} value={formValues.transactionDate} helperText={formErrors.transactionDate} name="transactionDate" required variant="outlined" label={t('transaction_date')} onChange={handleChange} size="small" error={formErrors?.transactionDate && formErrors.transactionDate.length > 0} />
              <br />
              <TextField sx={{ ml: 1, minWidth: '100%' }} inputRef={descriptionRef} helperText={formErrors.description} name="description" variant="outlined" label={t('description')} value={formValues.description} onChange={handleChange} size="small" />
              <br />
              {
                (((parseInt(formValues.transactionTypeID) === 1 && formValues.paymentSource === 'bank')) || (parseInt(formValues.transactionTypeID) === 2 && formValues.isCashBank === true)) ? '' : (
                  <>
                    <hr />
                    <FormControl size="small" sx={{ ml: 1, minWidth: 200 }}>
                      <InputLabel> {t('currency')}: </InputLabel>
                      <Select label={t('currency')} onChange={handleChange} name="currencyID" value={formValues.currencyID}>
                        {
                          currencies?.map((value) => {
                            return (<MenuItem key={value.currencyID} value={value.currencyID}>{value.currencyName}</MenuItem>)
                          })
                        }
                      </Select>
                      <FormHelperText>{formErrors.currencyID}</FormHelperText>
                    </FormControl>
                    <FormControl size="small" sx={{ ml: 1, minWidth: 100 }}>
                      {/* <InputLabel> {t('credit_debit')}: </InputLabel> */}
                      { 
                        isMobile.isMobile ? (<Select label={t('credit_debit')} onChange={handleChange} name="isCredit" value={formValues.isCredit}>
                        <MenuItem value={'1'}>Credit</MenuItem>
                        <MenuItem value={'0'}>Debit</MenuItem>
                      </Select>) : (<TextField sx={{ ml: 1 }} helperText="Press C for Credit and D for Debit" onKeyUp={handleKey} name="isCredit" value={formValues.isCredit === 1 ? 'Credit' : 'Debit'} variant="outlined" label={t('credit_debit')} size="small" />)
                      }
                      
                      <FormHelperText>{formErrors.isCredit}</FormHelperText>
                    </FormControl>

                    <TextField sx={{ ml: 1 }} helperText={formErrors.cash} name="cashAmount" variant="outlined" label={t('amount')} value={formValues.cashAmount} onChange={handleChange} size="small" />
                    {
                      [1, 2].includes(parseInt(formValues.transactionTypeID)) ? '' : (<>
                        <TextField sx={{ ml: 1 }} helperText={formErrors.exchangeRate} name="exchangeRate" variant="outlined" label={t('exchange_rate')} value={formValues.exchangeRate} onChange={handleChange} size="small" />
                        <TextField sx={{ ml: 1 }} disabled variant="outlined" value={formValues.conversion} size="small" />  </>
                      )
                    }

                    {
                      (parseInt(formValues.transactionTypeID) === 1 && formValues.paymentSource === 'party')
                      &&
                      <TextField sx={{ ml: 1 }} helperText={formErrors.partyNo} name="partyNo" variant="outlined" label={t('no')} value={formValues.partyNo} onChange={handleChange} size="small" />
                    }

                    <br />
                  </>
                )
              }


              {
                parseInt(formValues.transactionTypeID) === 2 && formValues.isCashBank !== true && (
                  <>
                    <hr />
                    <FormControl size="small" sx={{ ml: 1, minWidth: 200 }}>
                      <InputLabel> {t('cheque_bank_account')}: </InputLabel>
                      <Select label={t('cheque_bank_account')} onChange={handleChange} name="chequeBankAccountID" value={formValues.chequeBankAccountID}>
                        <MenuItem value="">{t('select_bank_account')} </MenuItem>
                        {
                          bankAccounts?.map((value) => {
                            return (<MenuItem key={value.bankAccountID} value={value.bankAccountID}>{value.bankName} [{value.accountNumber}]</MenuItem>)
                          })
                        }

                      </Select>
                    </FormControl>
                    <TextField sx={{ ml: 1 }} helperText={formErrors.chequeNumber} name="chequeNumber" variant="outlined" label={t('cheque_number')} value={formValues.chequeNumber} onChange={handleChange} size="small" />
                    <TextField sx={{ ml: 1 }} helperText={formErrors.chequeAmount} name="chequeAmount" variant="outlined" label={t('cheque_amount')} value={formValues.chequeAmount} onChange={handleChange} size="small" />
                    <TextField type="date" sx={{ ml: 1, minWidth: 200 }} value={formValues.chequeDate} name="chequeDate" variant="outlined" label={t('cheque_date')} onChange={handleChange} size="small" />
                    <br />

                  </>
                )
              }

              {
                ((parseInt(formValues.transactionTypeID) === 1 && formValues.paymentSource === 'bank') || (parseInt(formValues.transactionTypeID) === 2 && formValues.isCashBank === true)) && (
                  <>
                    <hr />
                    <FormControl size="small" sx={{ ml: 1, minWidth: 200 }}>
                      <InputLabel> {t('bank_account')}: </InputLabel>
                      <Select label={t('bank_account')} onChange={handleChange} name="bankAccountID" value={formValues.bankAccountID}>
                        <MenuItem value="">{t('bank_account')} </MenuItem>
                        {
                          bankAccounts?.map((value) => {
                            return (<MenuItem key={value.bankAccountID} value={value.bankAccountID}>{value.bankName} [{value.accountNumber}]</MenuItem>)
                          })
                        }

                      </Select>
                    </FormControl>
                    <TextField sx={{ ml: 1 }} helperText={formErrors.bankTransactionID} name="bankTransactionID" variant="outlined" label={t('bank_transaction_id')} value={formValues.bankTransactionID} onChange={handleChange} size="small" />
                    <FormControl size="small" sx={{ ml: 1, minWidth: 100 }}>
                      {/* <InputLabel> {t('credit_debit')}: </InputLabel> */}
                      {
                        isMobile.isMobile ? (<Select label={t('credit_debit')} onChange={handleChange} name="isBankCredit" value={formValues.isBankCredit}>
                          <MenuItem value={'1'}>Credit</MenuItem>
                          <MenuItem value={'0'}>Debit</MenuItem>
                        </Select>) : (
                          <TextField sx={{ ml: 1 }} helperText="Press C for Credit and D for Debit" onKeyUp={handleKey} name="isBankCredit" value={formValues.isBankCredit === 1 ? 'Credit' : 'Debit'} variant="outlined" label={t('credit_debit')} size="small" />
                        ) 
                      }
                      {/*<FormHelperText>{formErrors.isBankCredit}</FormHelperText> */}
                      
                    </FormControl>
                    <TextField sx={{ ml: 1 }} helperText={formErrors.bankAmount} name="bankAmount" variant="outlined" label={t('bank_amount')} value={formValues.bankAmount} onChange={handleChange} size="small" />
                    <TextField sx={{ ml: 1 }} readOnly variant="outlined" label={t('bank_balance')} value={bankBalance} size="small" />
                    <br />
                  </>
                )
              }
              {
                (parseInt(formValues.transactionTypeID) === 1 && formValues.paymentSource === 'referral_customer') && (
                  <>
                    <hr />
                    <TextField sx={{ ml: 1, minWidth: '20%' }} helperText={formErrors.customerName} name="customerName" variant="outlined" label={t('customer_name')} value={formValues.customerName} onChange={handleChange} size="small" error={formErrors?.customerName && formErrors.customerName.length > 0} />
                    <TextField sx={{ ml: 1 }} helperText={formErrors.transactionTypeNumber} name="transactionTypeNumber" variant="outlined" label={t('ref_no')} value={formValues.transactionTypeNumber} onChange={handleChange} size="small" />
                    <TextField sx={{ ml: 1, minWidth: '50%' }} name="customerDescription" variant="outlined" label={t('description')} value={formValues.customerDescription} onChange={handleChange} size="small" />
                  </>
                )
              }

              {
                (parseInt(formValues.transactionTypeID) === 1 && formValues.paymentSource === 'party' && partyTypeID !== 4) ? (
                  <>
                    <hr />
                    <FormControl size="small" sx={{ ml: 1, minWidth: 200 }} error={formErrors?.referralPartyID && formErrors.referralPartyID.length > 0}>
                      {/* <InputLabel> { t('party') }: </InputLabel> */}
                      
                      <Autocomplete
                        options={regularPartyList}
                        value={selectedReferralParty}
                        isOptionEqualToValue={(op,va) => op.partyID === va.partyID}
                        getOptionLabel={(option) => (option.partyName + ' ( ' + option.address + ') ')}
                        onChange={handleParty2Change}
                        renderInput={(params) => <TextField {...params} label={parseInt(formValues.transactionTypeID) === 1 ? t('payment_by') : t('received_from')} />}
                      />
                      <FormHelperText>{formErrors.referralPartyID}</FormHelperText>
                    </FormControl>

                    {/* <FormControl size="small" sx={{ ml: 1, minWidth: 200 }} error={formErrors?.referralPartyID && formErrors.referralPartyID.length > 0}>
                      <InputLabel> {parseInt(formValues.transactionTypeID) === 1 ? t('payment_by') : t('received_from')}: </InputLabel>
                      <Select label={parseInt(formValues.transactionTypeID) === 1 ? t('payment_by') : t('received_from')} onChange={handleChange} name="referralPartyID" value={formValues.referralPartyID}>
                        <MenuItem key={-1} value=""></MenuItem>
                        {
                          regularPartyList?.map((party) => (
                            <MenuItem key={party.partyID} value={party.partyID}>{party.partyName} ( {party.address} )</MenuItem>
                          ))
                        }
                      </Select>
                      <FormHelperText>{formErrors.referralPartyID}</FormHelperText>
                    </FormControl> */}
                    {
                      referralPartyCurrencies?.map((value) => {
                        return (
                          <TextField
                            size="small"
                            InputProps={{
                              readOnly: true,
                            }}
                            sx={{ ml: 1, maxWidth: 150 }}
                            key={'ref_' + value.currencyID}
                            id={"ref_currency_" + value.currencyID}
                            label={value.currencyName}
                            value={value.refCurrencyPayable || '0'}
                          />
                        )
                      }
                      )
                    }
                  </>
                ) : ('')
              }

              {
                (parseInt(formValues.transactionTypeID) === 1 && formValues.paymentSource === 'party' && partyTypeID !== 4)
                &&
                <>
                  <br />
                  <TextField sx={{ ml: 1 }} helperText={formErrors.referralPartyNo} name="referralPartyNo" variant="outlined" label={t('no')} value={formValues.referralPartyNo} onChange={handleChange} size="small" />
                </>
              }

              {
                (parseInt(formValues.transactionTypeID) === 1 && (formValues.paymentSource === 'party' && partyTypeID !== 4 || formValues.paymentSource === 'bank')) && (
                  <>
                    <TextField sx={{ ml: 1, minWidth: '50%' }} name="description2" variant="outlined" label={t('description')} value={formValues.description2} onChange={handleChange} size="small" />
                  </>
                )
              }



              {
                (parseInt(formValues.transactionTypeID) === 1 && formValues.paymentSource === 'party') && (formValues.cashAmount !== '' && formValues.cashAmount.toString().indexOf('-') === -1) && (formValues.bankAmount !== '' && formValues.bankAmount.toString().indexOf('-') === -1) &&
                <TextField type="date" sx={{ ml: 1, minWidth: 200 }} value={formValues.receivingDate} helperText={formErrors.receivingDate} name="receivingDate" required variant="outlined" label={t('receiving_date')} onChange={handleChange} size="small" error={formErrors?.receivingDate && formErrors.transactionDate.length > 0} />
              }

              <br />
              <br />

              <Button sx={{ ml: 2 }} variant="contained" onClick={handleSubmit}>{t(buttonText)}</Button>
              <Button sx={{ ml: 2 }} variant="contained" color="secondary" onClick={handleCancel}> {t('cancel')} </Button>
            </CardContent>
          </Card>
        </div>
      </Box>
      <br />
      <Card sx={{ bgcolor: grey[100] }}>
        <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: green[500] }}><ListIcon /></Avatar>
          }
          title={t('payment_receipt') + ' ' + t('list')} />
        <TextField type="date" inputRef={dateFilterRef} sx={{ ml: 30, mr: 10, minWidth: 200 }} value={transactionDateFilter} variant="outlined" label={t('date')} onChange={transactionDateChanged} size="small" />
        {
          Object.keys(cashBalances).map((k, i) => {
            return (<TextField type="text" readOnly key={'cashBalanceCurrencyID_' + i} name="cashBalance" sx={{ minWidth: 200 }} value={new Intl.NumberFormat('en-IR').format(cashBalances[k])} variant="outlined" label={t(k)} size="small" />)
          })
        }
        <CardContent sx={{ width: '100%' }}>
          <DataGrid
            density="compact"
            autoHeight
            rows={transactionData}
            columns={columns}
            pageSize={50}
            rowsPerPageOptions={[50]}
            getRowId={row => row.transactionDetailID}
            getCellClassName={(params) => {
              if (params.value && params.value.toString().substring(0, 1) === '-') {
                return 'minus';
              }
              return '';
            }}
          />
        </CardContent>
      </Card>
    </MEDrawer>
  )
}

export default PaymentReceipt;
