import PaidIcon from '@mui/icons-material/Paid';
import { Avatar, Card, CardContent, CardHeader } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom'
import MEDrawer from '../../components/MEDrawer';
import useAxios from '../../hooks/useAxios';
import { green, grey } from '@mui/material/colors';
import useLocalStorage from '../../hooks/useLocalStorage';

const AccountHeadLedger = () => {
    const { t } = useTranslation();

    const colSet1 = [
        { field: 'id', headerName: t('#'), width: 50, renderCell: (index) => index.api.getRowIndex(index.row.id) + 1 },
        { field: 'transactionDate', headerName: t('date'), width: 120 },
        { field: 'account', headerName: t('account'), width: 200 },
        { field: 'description', headerName: t('description'), width: 300 },
    ];

    const { id } = useParams();
    const [accountHeadLedger, setAccountHeadLedger] = useState([]);
    const [ledgerType, setLedgerType] = useState('cash');
    const [user, setUser] = useLocalStorage("user", null);
    const [accountHeadInfo, setAccountHeadInfo] = useState({});
    const [listError, sendListRequest] = useAxios(setData);
    const [currencies, setCurrencies] = useState({});
    const [columns, setColumns] = useState([]);

    function setData(data) {
        var currenciesData = data.data.currencies;
        setCurrencies(currenciesData);
        setAccountHeadInfo(data.data.party);
        setAccountHeadLedger(data.data.ledger);
        if (data.data.accountHead.headName === 'Buy' || data.data.accountHead.headName === 'Sell') {
            setLedgerType('buy_sell');
        } else {
            setLedgerType(data.data.accountHead.headName);
        }

        var aryCurrencies = [];
        currenciesData.map(currency => {
            aryCurrencies.push({ field: 'credit' + currency.currencyCode, 'headerName': t('credit') + ' (' + t(currency.currencyCode) + ')', width: 150, valueGetter: (params) => `${new Intl.NumberFormat('en-IR').format(params.row.currencyAmount[currency.currencyCode].credit)}` });
            aryCurrencies.push({ field: 'debit' + currency.currencyCode, 'headerName': t('debit') + ' (' + t(currency.currencyCode) + ')', width: 150, valueGetter: (params) => `${new Intl.NumberFormat('en-IR').format(params.row.currencyAmount[currency.currencyCode].debit)}` });
            aryCurrencies.push({ field: 'balance' + currency.currencyCode, 'headerName': t('balance') + ' (' + t(currency.currencyCode) + ')', width: 150, valueGetter: (params) => `${new Intl.NumberFormat('en-IR').format(params.row.currencyAmount[currency.currencyCode].balance)}` });
        });
        setColumns([...colSet1, ...aryCurrencies]);
    }

    useEffect(() => {
        sendListRequest({
            url: `/accountHead/${id}`,
            method: 'GET',
            body: {},
            headers: { Accept: 'application/json', Authorization: 'Bearer ' + user.access_token }
        })
    }, [id]);


    return (
        <MEDrawer>
            <Card sx={{ bgcolor: grey[100] }}>
                <CardHeader avatar={<Avatar sx={{ bgcolor: green[500] }}><PaidIcon /></Avatar>} title={t(ledgerType) + ' ' + t('account') + ' ' + t('ledger')} />
                <CardContent sx={{ width: '100%' }}>
                    <DataGrid
                        density="compact"
                        autoHeight
                        rows={accountHeadLedger}
                        getRowId={(row) => row.id}
                        columns={columns}
                        pageSize={50}
                        rowsPerPageOptions={[50]}
                        getCellClassName={(params) => {
                            if (params.value && params.value.toString().substring(0, 1) === '-') {
                                return 'minus';
                            }
                            return '';
                        }}
                    />
                </CardContent>
            </Card>
        </MEDrawer>
    )
}

export default AccountHeadLedger
