import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import langEN from './locales/en/translation.json';
import langUR from './locales/ur/translation.json';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: langEN
            },
            ur: {
                translation: langUR
            }
        },
        fallbackLng: ['en','ur'],
        lng: 'en',
        debug: true
    });


export default i18n;