import { useSearchParams } from 'react-router-dom';
import PKRLedgerReport from '../../components/PKRLedgerReport';

const ExpenseReport = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const startDate = searchParams.get('startDate');
    const endDate = searchParams.get('endDate');
    const subHeadID = 5;

    return <PKRLedgerReport url={`/accountHead/${subHeadID}?startDate=${startDate}&endDate=${endDate}`} startDate={startDate} endDate={endDate} reportName="expense_report" />
}

export default ExpenseReport