import { Box, Button, Card, CardContent, TextField, Typography } from '@mui/material';
import { useState } from 'react'
import MEDrawer from '../components/MEDrawer';
import { useAuth } from '../hooks/useAuth'
import { useTranslation } from 'react-i18next';
import useLocalStorage from '../hooks/useLocalStorage';
import useAxios from '../hooks/useAxios';
import { useNavigate } from 'react-router-dom';

const Profile = () => {
  const auth = useAuth();
  const initialValues = {
    name: auth.user.user_info.name,
    email: auth.user.user_info.email,
    password: ''
  };

  const [formValues, setFormValues] = useState(initialValues);
  const [error, sendPostRequest] = useAxios(getSavedData);
  const { t, i18n } = useTranslation();
  const [user, setUser] = useLocalStorage("user", null);
  const navigate = useNavigate();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({...formValues, [name] : value});
  }

  const handleLogout = () => {
    auth.logout();
    navigate('/login');
  }

  function getSavedData() {
    handleLogout();
  }
  
  const handleSubmit = (event) => {
    event.preventDefault();
    sendPostRequest({
      url: '/auth/update',
      method: 'PUT',
      body: formValues,
      headers: { Accept: 'application/json', Authorization: 'Bearer ' + user.access_token }
    });
  }

  const handleCancel = () => {

  }

  return (
    <MEDrawer>
        <Box sx={{ width: '100%' }}>
        <Typography variant="h3" gutterBottom>
            { auth.user.user_info.name }.
        </Typography>
        <Typography variant="h4" gutterBottom>
            { auth.user.user_info.email }.
        </Typography>
        <Card>
          <CardContent sx={{ width: '100%' }}>
              <TextField sx={{ ml: 1 }} name="name" required variant="outlined" label={ t('name') } value={formValues.name} onChange={handleChange} size="small" /><br /><br />
              <TextField sx={{ ml: 1 }} name="email" required variant="outlined" label={ t('email') } value={formValues.email} onChange={handleChange} size="small" /><br /><br />
              <TextField type="password" sx={{ ml: 1 }} helperText="Leave the field empty, if you don't want to change the password" name="password" variant="outlined" label={ t('password') } value={formValues.password} onChange={handleChange} size="small" /><br />
              <br />
              <br />
              <Button sx={{ ml: 1 }} variant="contained" onClick={handleSubmit}>{ t('update') }</Button>
              <Button sx={{ ml: 2 }} variant="contained" color="secondary" onClick={handleCancel}> {t('cancel')} </Button>
            </CardContent>
        </Card>
        </Box>
    </MEDrawer>
  )
}

export default Profile
