import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, FormHelperText } from '@mui/material'
import { useState, useEffect, useCallback } from 'react';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import MEDrawer from '../../components/MEDrawer';
import Toast from '../../components/Toast';
import useAxios from '../../hooks/useAxios';
import useLocalStorage from '../../hooks/useLocalStorage';
import useToast from '../../hooks/useToast';
import Avatar from '@mui/material/Avatar';
import { Card, CardContent, CardHeader } from '@mui/material';
import ListIcon from '@mui/icons-material/List';
import { blue, green, grey } from '@mui/material/colors';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';


const BankAccount = () => {

  const { t } = useTranslation();

  const navigate = useNavigate();

  const initialState = {bankID: '', accountTitle: '', accountNumber: '', branchCode: '', branchName: '',branchLocation: '', description: '', isCredit: 1,openingBalance: '',headID: '', createdByUserID: ''};
  const [formValues, setFormValues] = useState(initialState);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [bankAccountData, setBankAccountData] = useState([]);
  const [buttonText, setButtonText] = useState('save');
  const [editID, setEditID] = useState(0);
  const [isRequesting, setIsRequesting] = useState(false);
  const [bank, setBank] = useState([]);

  const [listError, sendListRequest] = useAxios(setData);
  const [editError, sendEditRequest] = useAxios(setEditData);
  const [deleteError, sendDeleteRequest] = useAxios(checkDeleted);

  const [error, sendPostRequest] = useAxios(getSavedData);

  const [user, setUser] = useLocalStorage("user", null);
  
  const { open, setOpen, responseType, setResponseType, responseMessage, setResponseMessage, handleClose } = useToast();

  function setData(data) {
    setBankAccountData(data.data.bankAccount);
    setBank(data.data.banks);
  }

  function setEditData(data) {
    console.log(data);
    setFormValues(data.data.bankAccount);
    setIsRequesting(false);
  }

  function checkDeleted(data) {
    if (data.data.success === true) {
      setResponseType('success'); 
      setResponseMessage(data.data.message);
    } else {
      setResponseType('error');
      setResponseMessage(data.message);
    }
    setOpen(true);
    fetchListData();
    setIsRequesting(false);
  }

  const validate = (values) => {
    const errors = {};

    if (isNaN(parseInt(values.bankID))) {
      errors.bankID =  t('bank') + ' ' + t('is required');
    }
    if (!values.accountTitle.length) {
      errors.accountTitle = t('account') + ' ' + t('is required');
    }
    if (!values.accountNumber) {
      errors.accountNumber =  t('account') + ' ' + t('number') + ' ' + t('is required');
    }
    if (!values.branchCode) {
      errors.branchCode = t('branch') + ' ' + t('code') + ' ' + t('is required');
    }
    if (!values.branchName.length) {
      errors.branchName = t('branch') + ' ' + t('name') + ' ' + t('is required');
    }
    if (!values.branchLocation.length) {
        errors.branchLocation = t('branch') + ' ' + t('location') + ' ' + t('is required') ;
    }

    return errors;
  }

  function getSavedData(data) {
    if (data.status === 200) {
      setResponseType('success');
      setResponseMessage(data.data.message);
      if (!data.data.success) {
        setResponseType('error');
        setResponseMessage(data.data.message.errorInfo[2]);
      }
      
      setFormValues(initialState);
    } else {
      setResponseType('error');
      setResponseMessage(data.data.message);
    }
    setOpen(true);
    fetchListData();
    setButtonText('save');
    setEditID(0);
    setIsRequesting(false);
  }

  const handleEdit = useCallback(
    (id) => () => {
      // navigate(`/currency/${id}`);
      if (isRequesting) return;
      setIsRequesting(true);
      setButtonText('update');
      setEditID(id);
      sendEditRequest({
        url: '/bankAccount/'+id,
        method: 'GET',
        body: null,
        headers: { Accept: 'application/json', Authorization: 'Bearer ' + user.access_token }
      });
    },[]
  );

  const handleBankLedger = (params, event, details) => {
    navigate('/bankAccount/'+params.id);
  }


  const handleDelete = useCallback(
    (id) => () => {
      if (window.confirm('Are you sure you want to delete this record?')) {
        if (isRequesting) return;
        setIsRequesting(true);
        sendDeleteRequest({
          url: '/bankAccount/'+id,
          method: 'DELETE',
          body: {},
          headers: { Accept: 'application/json', Authorization: 'Bearer ' + user.access_token }
        });
      }
    },[]
  );

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({...formValues, [name] : value});
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
  };

  const handleCancel = () => {
    setFormValues(initialState);
    setButtonText('save');
    setEditID(0);
    setIsSubmit(false);
    setFormErrors({});
  }

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      if (isRequesting) return;
      setIsRequesting(true);
      if (!isNaN(parseInt(editID)) && editID > 0) {
        sendPostRequest({
          url: '/bankAccount/'+editID,
          method: 'PUT',
          body: formValues,
          headers: { Accept: 'application/json', Authorization: 'Bearer ' + user.access_token }
        });
      } else {
        sendPostRequest({
          url: '/bankAccount',
          method: 'POST',
          body: formValues,
          headers: { Accept: 'application/json', Authorization: 'Bearer ' + user.access_token }
        });
      }      
    }
  },[formErrors]);

  useEffect(() => {
    fetchListData();
  },[]);

  function fetchListData() {
    sendListRequest({
      url: '/bankAccount',
      method: 'GET',
      body: {},
      headers: { Accept: 'application/json', Authorization: 'Bearer ' + user.access_token }
    })
  }

  const columns = [
    { field: 'bankName', headerName: t('bank') , width: 200},
    { field: 'accountTitle', headerName: t('account') + ' ' + t('title') , width: 200},
    { field: 'accountNumber', headerName: t('account') + ' ' + t('number') , width: 200},
    { field: 'branchCode', headerName: t('branch_code') , width: 100},
    { field: 'branchName', headerName: t('branch_name') , width: 150},
    { field: 'balanceInPKR', headerName: t('balance'), width: 160},
    { field: 'branchLocation', headerName: t('branch_location')  , width: 150},
    { field: 'dateCreated', headerName: t('created_on') , width: 160},
    { field: 'Action', headerName: t('action') , width: 100, type: 'actions', getActions: (params, GridRowParams) => [
      <GridActionsCellItem icon={<DeleteIcon />} onClick={handleDelete(params.id)} label={ t('delete') } />,
      <GridActionsCellItem icon={<EditIcon />} onClick={handleEdit(params.id)} label={ t('edit') } />
    ]}
  ];

  return (
    <MEDrawer>
        <Box>
            <Toast open={open} handleClose={handleClose} responseType={responseType} responseMessage={responseMessage} />
            <div>
            <Card sx={{ bgcolor: blue[50]}}>
            <CardHeader 
            avatar={
              <Avatar sx={{ bgcolor: green[500] }}><AccountBalanceIcon  /></Avatar>
            }
            title={t('bank') + ' ' + t('account') + ' ' + t('form')} />
            <CardContent sx={{ width: '100%' }}>
                <FormControl size="small" sx={{ ml: 1, minWidth: 200  }} >
                    <InputLabel> { t('bank')}: </InputLabel>
                    <Select label={ t('bank') }  onChange={handleChange} name="bankID" value={formValues.bankID} error={formErrors?.bankID && formErrors.bankID > 0}>
                    <MenuItem value="">Select Bank</MenuItem>
                    {
                        bank?.map((value)=>{
                            return(<MenuItem key={value.bankID} value={value.bankID}>{value.bankName}</MenuItem>);
                        })
                    }
                    </Select>
                    <FormHelperText>{formErrors.bankID}</FormHelperText>
                </FormControl>
                <TextField sx={{ ml: 1 }} helperText={formErrors.accountTitle}  name="accountTitle" required variant="outlined"    label={t('account') + ' ' + t('title')} value={formValues.accountTitle} onChange={handleChange} size="small" error={formErrors?.accountTitle && formErrors.accountTitle > 0} />
                <TextField sx={{ ml: 1 }} helperText={formErrors.accountNumber} name="accountNumber" required variant="outlined"  label={t('account') + ' ' + t('number')} value={formValues.accountNumber} onChange={handleChange} size="small" error={formErrors?.accountNumber && formErrors.accountNumber > 0} />
                <TextField sx={{ ml: 1 }} helperText={formErrors.branchCode}    name="branchCode" required variant="outlined"        label={t('branch_code')} value={formValues.branchCode} onChange={handleChange} size="small" error={formErrors?.branchCode && formErrors.branchCode > 0}  />
                <TextField sx={{ ml: 1 }} helperText={formErrors.branchName}    name="branchName" required variant="outlined"        label={t('branch_name') } value={formValues.branchName} onChange={handleChange} size="small" error={formErrors?.branchName && formErrors.branchName > 0} />
                <TextField sx={{ ml: 1 }} helperText={formErrors.branchLocation} name="branchLocation" required variant="outlined"  label={t('branch_location')} value={formValues.branchLocation} onChange={handleChange} size="small" error={formErrors?.branchLocation && formErrors.branchLocation > 0} />
                <br /><br />
                <TextField fullWidth sx={{ ml: 1 }} helperText={formErrors.description}   name="description" required variant="outlined"      label={t('description')} value={formValues.description} onChange={handleChange} size="small"/>
                <br/>
                <br/>
                {
                  editID === 0 && (
                    <>
                    <FormControl size="small" sx={{ ml: 1, minWidth: 100 }}>
                      <InputLabel> {t('credit_debit')}: </InputLabel>
                      <Select label={t('credit_debit')} onChange={handleChange} name="isCredit" value={formValues.isCredit}>
                        <MenuItem value={'1'}>Credit</MenuItem>
                        <MenuItem value={'0'}>Debit</MenuItem>
                      </Select>
                      <FormHelperText>{formErrors.isCredit}</FormHelperText>
                    </FormControl>

                      <TextField sx={{ ml: 1 }} helperText={formErrors.openingBalance} name="openingBalance" variant="outlined" label={t('opening_balance')} value={formValues.openingBalance} onChange={handleChange} size="small"/>
                    </>
                    )
                }                
                <br/>
                <br/>
                <Button sx={{ ml: 1 }} variant="contained" onClick={handleSubmit}>  { t(buttonText)}</Button>
                <Button sx={{ ml: 2 }} variant="contained" color="secondary" onClick={handleCancel}> { t('cancel')} </Button>  
            </CardContent>
        </Card>
          </div>
        </Box>
        <br />
        <Card sx={{ bgcolor: grey[100]}}>
          <CardHeader 
            avatar={
              <Avatar sx={{ bgcolor: green[500] }}><ListIcon  /></Avatar>
            }
            title={t('bank') + ' ' + t('account') + ' ' + t('list')} />
          <CardContent sx={{ width: '100%' }}>
            <DataGrid 
              density="compact"
              autoHeight
              rows={bankAccountData}
              columns={columns}
              pageSize={50}
              rowsPerPageOptions={[50]}
              onRowDoubleClick={handleBankLedger}
              getRowId={row => row.bankAccountID}
              getCellClassName={(params) => {
                  if (params.value && params.value.toString().substring(0,1) === '-') {
                      return 'minus';
                  }
                  return '';
              }}
            />
          </CardContent>
        </Card>
    </MEDrawer>
  )
}

export default BankAccount;
