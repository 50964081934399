import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, FormHelperText } from '@mui/material'
import { useState, useEffect, useCallback } from 'react';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import MEDrawer from '../../components/MEDrawer';
import Toast from '../../components/Toast';
import useAxios from '../../hooks/useAxios';
import useLocalStorage from '../../hooks/useLocalStorage';
import useToast from '../../hooks/useToast';
import Avatar from '@mui/material/Avatar';
import { Card, CardContent, CardHeader } from '@mui/material';
import ListIcon from '@mui/icons-material/List';
import { blue, green, grey } from '@mui/material/colors';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';
import { useParams } from 'react-router-dom';
import { ShoppingCartCheckout } from '@mui/icons-material';

const todayDate = moment().format("YYYY-MM-DD");

const ExpenseAccount = () => {

  const { t } = useTranslation();

  const { id } = useParams();

  const initialState = {expenseDate: todayDate, description: '', amount: ''};
  const [formValues, setFormValues] = useState(initialState);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [expenseAccountData, setExpenseAccountData] = useState([]);
  const [buttonText, setButtonText] = useState('save');
  const [editID, setEditID] = useState(0);
  const [isRequesting, setIsRequesting] = useState(false);

  const [listError, sendListRequest] = useAxios(setData);
  const [editError, sendEditRequest] = useAxios(setEditData);
  const [deleteError, sendDeleteRequest] = useAxios(checkDeleted);

  const [error, sendPostRequest] = useAxios(getSavedData);

  const [user, setUser] = useLocalStorage("user", null);
  
  const { open, setOpen, responseType, setResponseType, responseMessage, setResponseMessage, handleClose } = useToast();

  function setData(data) {
    setExpenseAccountData(data.data.ledger);
  }

  function setEditData(data) {
    setFormValues(data.data.data);
    setIsRequesting(false);
  }

  function checkDeleted(data) {
    if (data.data.success === true) {
      setResponseType('success'); 
      setResponseMessage(data.data.message);
    } else {
      setResponseType('error');
      setResponseMessage(data.message);
    }
    setOpen(true);
    fetchListData();
    setIsRequesting(false);
  }

  const validate = (values) => {
    const errors = {};

    if (!values.amount.length) {
      errors.amount =  t('amount') + ' ' + t('is required');
    }
    
    return errors;
  }

  function getSavedData(data) {
    if (data.status === 200) {
      setResponseType('success');
      setResponseMessage(data.data.message);
      if (!data.data.success) {
        setResponseType('error');
        setResponseMessage(data.data.message.errorInfo[2]);
      }
      
      setFormValues(initialState);
    } else {
      setResponseType('error');
      setResponseMessage(data.data.message);
    }
    setOpen(true);
    fetchListData();
    setButtonText('save');
    setEditID(0);
    setIsRequesting(false);
  }

  const handleEdit = useCallback(
    (id) => () => {
      if (isRequesting) return;
      setIsRequesting(true);
      setButtonText('update');
      setEditID(id);
      sendEditRequest({
        url: '/expenseAccount/'+id,
        method: 'GET',
        body: null,
        headers: { Accept: 'application/json', Authorization: 'Bearer ' + user.access_token }
      });
    },[]
  );


  const handleDelete = useCallback(
    (id) => () => {
      if (window.confirm('Are you sure you want to delete this record?')) {
        if (isRequesting) return;
        setIsRequesting(true);
        sendDeleteRequest({
          url: '/accountHead/transaction/delete?' + id,
          method: 'DELETE',
          body: {},
          headers: { Accept: 'application/json', Authorization: 'Bearer ' + user.access_token }
        });
      }
    },[]
  );

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({...formValues, [name] : value});
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
  };

  const handleCancel = () => {
    setFormValues(initialState);
    setButtonText('save');
    setEditID(0);
    setIsSubmit(false);
    setFormErrors({});
  }

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      if (isRequesting) return;
      setIsRequesting(true);
      if (!isNaN(parseInt(editID)) && editID > 0) {
        sendPostRequest({
          url: '/expenseAccount/'+editID,
          method: 'PUT',
          body: formValues,
          headers: { Accept: 'application/json', Authorization: 'Bearer ' + user.access_token }
        });
      } else {
        sendPostRequest({
          url: '/accountHead/recordExpense',
          method: 'POST',
          body: formValues,
          headers: { Accept: 'application/json', Authorization: 'Bearer ' + user.access_token }
        });
      }      
    }
  },[formErrors]);

  useEffect(() => {
    fetchListData();
  },[]);

  function fetchListData() {
    sendListRequest({
      url: `/accountHead/${id}`,
      method: 'GET',
      body: {},
      headers: { Accept: 'application/json', Authorization: 'Bearer ' + user.access_token }
    })
  }

  const columns = [
    { field: 'transactionDate', headerName: t('date') , width: 200},
    { field: 'amount', headerName: t('amount') , width: 200, valueGetter: (params) => `${params.row.currencyAmount.PKR.debit}`},
    { field: 'description', headerName: t('description'), width: 700},
    { field: 'Action', headerName: t('action') , width: 100, type: 'actions', getActions: (params, GridRowParams) => [
      <GridActionsCellItem icon={<DeleteIcon />} onClick={handleDelete(params.id)} label={ t('delete') } />,
      <GridActionsCellItem icon={<EditIcon />} onClick={handleEdit(params.id)} label={ t('edit') } />
    ]}
  ];

  return (
    <MEDrawer>
        <Box>
            <Toast open={open} handleClose={handleClose} responseType={responseType} responseMessage={responseMessage} />
            <div>
            <Card sx={{ bgcolor: blue[50]}}>
            <CardHeader 
            avatar={
              <Avatar sx={{ bgcolor: green[500] }}><ShoppingCartCheckout /></Avatar>
            }
            title={t('expense') + ' ' + t('account') + ' ' + t('form')} />
            <CardContent sx={{ width: '100%' }}>
                <TextField type="date" sx={{ ml: 1, minWidth: 200 }} value={formValues.expenseDate} helperText={formErrors.expenseDate} name="expenseDate" required variant="outlined" label={t('expense_date')} onChange={handleChange} size="small" error={formErrors?.expenseDate && formErrors.expenseDate.length > 0} />
                <br /><br />
                <TextField sx={{ ml: 1 }} helperText={formErrors.amount}    name="amount" required variant="outlined" label={t('amount')} value={formValues.amount} onChange={handleChange} size="small" error={formErrors?.amount && formErrors.amount > 0}  />
                <br /><br />
                <TextField fullWidth sx={{ ml: 1 }} helperText={formErrors.description} name="description" required variant="outlined" label={t('description')} value={formValues.description} onChange={handleChange} size="small"/>
                <br/>
                <br/>
                <Button sx={{ ml: 1 }} variant="contained" onClick={handleSubmit}>  { t(buttonText)}</Button>
                <Button sx={{ ml: 2 }} variant="contained" color="secondary" onClick={handleCancel}> { t('cancel')} </Button>  
            </CardContent>
        </Card>
          </div>
        </Box>
        <br />
        <Card sx={{ bgcolor: grey[100]}}>
          <CardHeader 
            avatar={
              <Avatar sx={{ bgcolor: green[500] }}><ListIcon  /></Avatar>
            }
            title={t('expense') + ' ' + t('account') + ' ' + t('list')} />
          <CardContent sx={{ width: '100%' }}>
            <DataGrid 
              density="compact"
              autoHeight
              rows={expenseAccountData}
              columns={columns}
              pageSize={50}
              rowsPerPageOptions={[50]}
              getCellClassName={(params) => {
                  if (params.value && params.value.toString().substring(0,1) === '-') {
                      return 'minus';
                  }
                  return '';
              }}
            />
          </CardContent>
        </Card>
    </MEDrawer>
  )
}

export default ExpenseAccount;
