import { useSearchParams } from 'react-router-dom';
import LedgerReport from '../../components/LedgerReport';

const DailyCashReport = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const reportDate = searchParams.get('reportDate');

    return <LedgerReport url={`/accountHead/allAccountsBalance?reportDate=${reportDate}&reportType=all`} date={reportDate} isAddSubHeadColumn={true} reportName="daily_cash_book" />
}

export default DailyCashReport