import { createContext, useContext, useState} from 'react'
import useLocalStorage from './useLocalStorage';

const AuthContext = createContext(null);

export const AuthProvider = ( { children }) => {
    const [user, setUser] = useLocalStorage("user", null);

    const login = (user) => {
        setUser(user);
    }

    const logout = () => {
        setUser(null);
    }

    return (
        <AuthContext.Provider value={{ user, login, logout }}>
            { children }
        </AuthContext.Provider>
    )
}

export const useAuth = () => {
    return useContext(AuthContext);
}