import { Button } from '@mui/material';
// import jsPDF from 'jspdf';
// import autoTable from 'jspdf-autotable';
import useAxios from '../../hooks/useAxios';
import useLocalStorage from '../../hooks/useLocalStorage';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useEffect } from 'react';

const PartyBalanceReport = () => {

    const [currencies, setCurrencies] = useState([]);
    const [currencyTotal, setCurrencyTotal] = useState({});
    const [partyData, setPartyData] = useState([]);
    const [user, setUser] = useLocalStorage("user", null);
    const [listError, sendListRequest] = useAxios(setData);
    const [isShowTotal, setIsShowTotal] = useState(false);

    const { t, i18n } = useTranslation();

    function setData(data) {
        console.log(data.data);
        setCurrencyTotal(data.data.balance);
        setCurrencies(data.data.currencies);
        setPartyData(data.data.parties);
    }

    const handlePrintPDF = () => {
        // const doc = new jsPDF();
        // autoTable(doc, { html: '#party-balance-report' })
        // doc.save('dailyCashBook.pdf');
        window.print();
    }

    function fetchListData() {
        sendListRequest({
            url: `/party`,
            method: 'GET',
            body: {},
            headers: { Accept: 'application/json', Authorization: 'Bearer ' + user.access_token }
        })
    }

    useEffect(() => {
        fetchListData();
    }, []);

    return (
        <div className="report">
            <Button type="button" onClick={handlePrintPDF}>Print</Button>
            <h3 style={{ textAlign: 'center' }}>{t('parties_balance')}</h3>
            <table cellPadding="5" id="party-balance-report" style={{ width: '100%', borderCollapse: 'collapse' }} border="1">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>{t('party')}</th>
                        <th>{t('phone')}</th>
                        {
                            currencies?.map((currency) => <th key={currency.currencyID}>{t(currency.currencyName)}</th>)
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        partyData.length && partyData.map((party, index) => {
                            var currenciesBalance = party.balance;
                            return (
                                <tr key={party.partyID}>
                                    <td>{index + 1} </td>
                                    <td>{party.partyName} ( {party.partyTypeName} )</td>
                                    <td>{party.phoneNumber}</td>
                                    {
                                        currencies?.map((currency) => {
                                            var amountColor = 'black';
                                            var payable = parseInt(currenciesBalance[currency.currencyCode].totalPayable);
                                            if (payable != 0) {
                                                amountColor = payable < 0 ? 'red' : 'green';
                                            }
                                            return (
                                                <td align="right" key={currency.currencyID} style={{ color: amountColor }}>
                                                    {payable ? new Intl.NumberFormat('en-IR').format(payable.toString().replaceAll(',', '')) : '0'}
                                                </td>
                                            )
                                        })
                                    }
                                </tr>
                            )
                        })
                    }
                    {
                        !partyData.length && <tr><td colSpan={currencies.length + 4}>No Record Found</td></tr>
                    }
                </tbody>
                <tfoot>
                    <tr style={{ fontWeight: 'bold' }}>
                        <td colSpan="3" align="right">{t('balance')}</td>
                        {
                            currencies.map((currency) => {
                                var totalColor = 'white';
                                if (currencyTotal[currency.currencyCode].totalPayable > 0) {
                                    totalColor = '#A3F7A4';
                                } else if (currencyTotal[currency.currencyCode].totalPayable < 0) {
                                    totalColor = '#FFADA5';
                                }
                                return (<td align="right" key={currency.currencyID} style={{ backgroundColor: totalColor }}>
                                    { new Intl.NumberFormat('en-IR').format(currencyTotal[currency.currencyCode].totalPayable.toString().replaceAll(',', '')) }
                                </td>
                                )
                            })
                        }
                    </tr>
                </tfoot>
            </table>
        </div>
    )
}

export default PartyBalanceReport