import { Navigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth"

const RequireAuth = ({ children }) => {
    const auth = useAuth();
    
    if (!auth.user) {
        return <Navigate to='/login' />
    }

    return children;
}

export default RequireAuth
