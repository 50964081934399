import { useSearchParams } from 'react-router-dom';
import LedgerReport from '../../components/LedgerReport';

const PartyCurrencyLedgerReport = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const endDate = searchParams.get('endDate');
    const partyID = searchParams.get('partyID');
    const partyCurrencyID = searchParams.get('currencyID');

    return <LedgerReport url={`/party/${partyID}?partyCurrencyID=${partyCurrencyID}&endDate=${endDate}`} currencyID={partyCurrencyID} partyID={partyID} endDate={endDate} reportName="party_ledger" />
}

export default PartyCurrencyLedgerReport