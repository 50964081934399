import useAxios from '../../hooks/useAxios';
import useLocalStorage from '../../hooks/useLocalStorage';
import { useState } from 'react';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import BalanceReport from '../../components/BalanceReport';
import { Button } from '@mui/material';

const BalanceSheetReport = (props) => {
    const partyCurrencyID = 1;
    const [searchParams, setSearchParams] = useSearchParams();
    const date = searchParams.get('date');

    const [transactionData, setTransactionData] = useState([]);
    const [totalCredits, setTotalCredits] = useState(0);
    const [totalDebits, setTotalDebits] = useState(0);
    const [expense, setExpense] = useState(0);
    const [currency, setCurrency] = useState({});
    const [user, setUser] = useLocalStorage("user", null);
    const [profitLoss, setProfitLoss] = useState(0);
    const [listError, sendListRequest] = useAxios(setData);

    function setData(data) {
        if (data.data === "") {
            return;
        }
        var selectedCurrency = data.data.currencies.filter(currency => parseInt(currency.currencyID) === parseInt(partyCurrencyID))[0];
        setCurrency(selectedCurrency);
        setTransactionData(data.data.results);
        var totalDebits = parseInt(data.data.totalDebits['PKR']);
        var totalCredits = parseInt(data.data.totalCredits['PKR']);
        var profitLoss = data.data.profitLoss;
        setTotalCredits(totalCredits);
        setTotalDebits(totalDebits);
        setProfitLoss(profitLoss);
        setExpense(data.data.expenseAmount);
        // setCurrency(data.data.currency);
    }

    function fetchListData(isMerge = false) {
        var thisURL = `/accountHead/allAccountHeadsBalance?dateType=upto&date=${date}&partyCurrencyID=${partyCurrencyID}&reportType=party`;
        if (isMerge === true) {
            thisURL += '&mergeExpense=1'
        }
        sendListRequest({
            url: thisURL,
            method: 'GET',
            body: {},
            headers: { Accept: 'application/json', Authorization: 'Bearer ' + user.access_token }
        })
    }

    useEffect(() => {
        fetchListData();
    }, []);

    return (
        <>
            <Button onClick={() => {fetchListData(true)}} sx={{ margin: '0 auto', display: 'flex'}} size="small" variant='outlined' className="text-center">Merge Expense</Button>
            <BalanceReport transactionData={transactionData} totalDebits={totalDebits} date={date} profitLoss={profitLoss} expenseAmount={expense} currency={currency} totalCredits={totalCredits} isShowProfitLoss={true} reportName="balance_sheet" />
        </>
    )
}

export default BalanceSheetReport