import { Button } from '@mui/material';
import React from 'react'
import { useTranslation } from 'react-i18next';

const BalanceReport = (props) => {
    const { transactionData, currency, date, totalDebits, totalCredits, profitLoss, expenseAmount, isShowProfitLoss, reportName } = props;
    console.log(props);
    const { t } = useTranslation();
    const handlePrintPDF = () => {
        window.print();
    }
    return (
        <div className="report">
            <Button type="button" onClick={handlePrintPDF}>Print</Button>
            <h4 style={{ textAlign: 'center' }}>{t(reportName)} {date !== null ? ("(" + date + ")") : ''}</h4>
            <h4 style={{ textAlign: 'center' }}> Currency: {currency.currencyName} </h4>
            {
                <table cellPadding="5" id="daily-cash-report" style={{ width: '100%', borderCollapse: 'collapse' }} border="1">
                    <thead>
                        <tr>
                            <th>{t('account')}</th>
                            <th>{t('credit')}</th>
                            <th>{t('debit')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            parseInt(expenseAmount) > 0 && (<tr>
                                <td>Expense</td>
                                <td></td>
                                <td>{expenseAmount}</td>
                            </tr>)
                        }
                        {
                            transactionData.length ? transactionData.map((transaction, index) => {
                                var debitAmount = parseInt(transaction.amount) < 0 ? Math.abs(transaction.amount) : '';
                                var creditAmount = parseInt(transaction.amount) > 0 ? transaction.amount : '';
                                var rowClass = debitAmount > 0 ? 'color-red' : 'color-green';
                                return (
                                    <tr key={index + 1} className={rowClass}>
                                        <td>{transaction.subHeadName}</td>
                                        <td>{creditAmount > 0 && new Intl.NumberFormat('en-IR').format(creditAmount.toString().replaceAll(',', ''))}</td>
                                        <td>{debitAmount > 0 && new Intl.NumberFormat('en-IR').format(debitAmount.toString().replaceAll(',', ''))}</td>
                                    </tr>
                                );
                            }) : <tr><td colSpan="3">No Record Found</td></tr>
                        }
                        {
                            (isShowProfitLoss === true && parseInt(profitLoss) !== 0) ? (
                                <tr>
                                    <td>{parseInt(profitLoss) > 0 ? 'Loss' : 'Profit'}</td>
                                    <td>{parseInt(profitLoss) < 0 ? new Intl.NumberFormat('en-IR').format(Math.abs(profitLoss).toString().replaceAll(',', '')) : ''}</td>
                                    <td>{parseInt(profitLoss) > 0 ? new Intl.NumberFormat('en-IR').format(profitLoss.toString().replaceAll(',', '')) : ''}</td>
                                </tr>
                            ) : ''
                        }
                    </tbody>
                    <tfoot>
                        <tr>
                            <td align='right'>Total:</td>
                            <td>{new Intl.NumberFormat('en-IR').format(totalCredits.toString().replaceAll(',', ''))}</td>
                            <td>{new Intl.NumberFormat('en-IR').format(totalDebits.toString().replaceAll(',', ''))}</td>
                        </tr>
                    </tfoot>
                </table>
            }
        </div>
    )
}

export default BalanceReport