import { useSearchParams } from 'react-router-dom';
import LedgerReport from '../../components/LedgerReport';

const BankLedgerReport = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const bankAccountID = searchParams.get('bankAccountID');

    return <LedgerReport url={`/bankAccount/${bankAccountID}`} currencyID={1} reportName="ledger_name" isIncludeGNRN={false} />
}

export default BankLedgerReport