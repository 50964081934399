import React from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const NotFound = () => {
  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="h1" gutterBottom>
        You're in the wrong place.
      </Typography>
    </Box>
  )
}

export default NotFound
