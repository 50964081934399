import { forwardRef } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Toast = ({ open, handleClose, responseType, responseMessage }) => {
  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{horizontal: 'center', vertical: 'top'}}>
        <Alert onClose={handleClose} severity={responseType} sx={{width: '100%'}}>{responseMessage}</Alert>
    </Snackbar>
  )
}

export default Toast;
