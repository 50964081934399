import { Route, Routes } from "react-router-dom";
import Dashboard from "./views/Dashboard";
import Login from "./views/Login";
import Currency from "./views/Currency/Currency";
import Party from "./views/Party/Party";
import Profile from "./views/Profile";
import BankAccount from "./views/BankAccount/BankAccount";
import Reports from "./views/Reports/Reports";
import DailyCashReport from "./views/Reports/DailyCashReport";
import PaymentReceipt from "./views/PaymentReceipt/PaymentReceipt";
import { AuthProvider } from "./hooks/useAuth";
import RequireAuth from "./components/RequireAuth";
import NotFound from "./components/NotFound";
import PartyLedger from "./views/Party/PartyLedger";
import BankAccountLedger from "./views/BankAccount/BankAccountLedger";
import AccountHeadLedger from "./views/AccountHead/AccountHeadLedger";
import './global.css';
import PartyLedgerReport from "./views/Reports/PartyLedgerReport";
import PartyBalanceReport from "./views/Reports/PartyBalanceReport";
import BankLedgerReport from "./views/Reports/BankLedgerReport";
import PartyCurrencyLedgerReport from "./views/Reports/PartyCurrencyLedgerReport";
import BalanceSheetReport from "./views/Reports/BalanceSheetReport";
import PartiesCurrencyLedgerBalanceReport from "./views/Reports/PartiesCurrencyLedgerBalanceReport";
import Backup from "./views/Backup";
import ExpenseAccount from "./views/Expense/ExpenseAccount";
import ExpenseReport from "./views/Reports/ExpenseReport";

function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route path="/" element={<RequireAuth><Dashboard /></RequireAuth>} />
        <Route path="/login" element={<Login />} />
        <Route path="/profile" element={<RequireAuth><Profile /></RequireAuth>} />
        <Route path="/currency" element={<RequireAuth><Currency /></RequireAuth>} />
        <Route path="/party/:id" element={<RequireAuth><PartyLedger /></RequireAuth>} />
        <Route path="/party" element={<RequireAuth><Party /></RequireAuth>} />
        <Route path="/bankAccount/:id" element={<RequireAuth><BankAccountLedger /></RequireAuth>} />
        <Route path="/accountHead/:id" element={<RequireAuth><AccountHeadLedger /></RequireAuth>} />
        <Route path="/bankAccount" element={<RequireAuth><BankAccount /></RequireAuth>} />
        <Route path="/paymentReceipt" element={<RequireAuth><PaymentReceipt /></RequireAuth>} />
        <Route path="/backup" element={<RequireAuth><Backup /></RequireAuth>} />
        <Route path="/expense/:id" element={<RequireAuth><ExpenseAccount /></RequireAuth>} />
        <Route path="/reports">
          <Route index element={<RequireAuth><Reports /></RequireAuth>} />
          <Route path="dailyCashBook" element={<DailyCashReport />} />
          <Route path="partyLedgerReport" element={<PartyLedgerReport />} />
          <Route path="partyCurrencyLedgerReport" element={<PartyCurrencyLedgerReport />} />
          <Route path="partyBalanceReport" element={<PartyBalanceReport />} />
          <Route path="bankLedgerReport" element={<BankLedgerReport />} />
          <Route path="balanceSheetReport" element={<BalanceSheetReport />} />
          <Route path="partiesCurrencyLedgerBalanceReport" element={<PartiesCurrencyLedgerBalanceReport />} />
          <Route path="expenseReport" element={<ExpenseReport />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </AuthProvider>
  );
}

export default App;
