import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import LanguageIcon from '@mui/icons-material/Language';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useAuth } from '../hooks/useAuth';
import { Link, useNavigate } from 'react-router-dom';
import { Avatar, ListItemIcon, Menu, MenuItem } from '@mui/material';
import Logout from '@mui/icons-material/Logout';
import { useTranslation } from 'react-i18next';

const drawerWidth = '100%';


function MEDrawer(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [toggleLanguage, setToggleLanguage] = React.useState('ur');
  const auth = useAuth();

  const { t, i18n } = useTranslation();

  const authNavItems = [
    {'url' : '/currency', 'label' : t('currency')},
    {'url' : '/party', 'label' : t('parties') },
    {'url' : '/bankAccount', 'label' : t('banks')},
    {'url' : '/accountHead/14', 'label' : t('cash')},
    {'url' : '/accountHead/10', 'label' : t('buy_sell')},
    {'url' : '/paymentReceipt', 'label' : t('payment_receipt')},
    {'url' : '/reports', 'label' : t('reports')}
  ];

  const publicNavItems = [{
      'url' : '/login',
      'label' : t('login')
  }];

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const changeLanguage = (lng) => {
    setToggleLanguage(lng == 'en' ? 'ur' : 'en');
    document.documentElement.lang = toggleLanguage;
    document.documentElement.dir = lng == 'ur' ? 'rtl' : 'ltr';
    i18n.changeLanguage(lng);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        <Link to="/">Money Exchanger</Link>
      </Typography>
      <Divider />
      <List>
        {auth.user?.access_token && authNavItems.map((item) => (
          <ListItem key={item.url} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText primary={item.label} />
            </ListItemButton>
          </ListItem>
        ))}
        {auth.user === null && publicNavItems.map((item) => (
          <ListItem key={item.url} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText primary={item.label} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;
  
  const navigate = useNavigate();
  
  const handleLogout = () => {
    auth.logout();
    navigate('/login');
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <CssBaseline />
      <AppBar component="nav">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
          >
            <Link to="/">Money Exchanger</Link>
          </Typography>
          <Box sx={{ flexGrow: 2, display: { xs: 'none', sm: 'block' } }}>
            {auth.user?.access_token && (
                authNavItems.map((item) => (
                    <Button key={item.url} sx={{ color: '#fff' }} onClick={() => { navigate(item.url)}}>
                      {item.label}
                    </Button>
                ))
            )}
            {auth.user?.access_token &&  <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar sx={{ width: 32, height: 32 }}>{auth.user?.user_info.name.charAt(0)}</Avatar>
        </IconButton>}
            {auth.user === null && publicNavItems.map((item) => (
              <Button key={item.url} sx={{ color: '#fff' }} to={() => { navigate(item.url)}}>
                {item.label}
              </Button>
            ))}
          </Box>
          <Box sx={{ justifyContent: 'end', alignItems: 'end' }}>
            <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={handleClose}
                  onClick={handleClose}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
              <MenuItem onClick={() => { navigate('/profile')}}>
                <Avatar /> { t('profile') }
              </MenuItem>
              <Divider />
              <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                { t('logout') }
              </MenuItem>
              <MenuItem onClick={() => changeLanguage(toggleLanguage)}>
                <ListItemIcon>
                  <LanguageIcon fontSize="small" />
                </ListItemIcon>
                { t(toggleLanguage) }
              </MenuItem>
            </Menu>
          </Box>
          
        
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box component="main" sx={{ p: 3 }}>
        <Toolbar />
        { props.children }
      </Box>
    </Box>
  );
}

MEDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default MEDrawer;