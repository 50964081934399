import useAxios from '../../hooks/useAxios';
import useLocalStorage from '../../hooks/useLocalStorage';
import { useState } from 'react';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import BalanceReport from '../../components/BalanceReport';

const PartiesCurrencyLedgerBalanceReport = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const partyCurrencyID = searchParams.get('currencyID');
    const date = searchParams.get('date');
    const dateType = searchParams.get('dateType');

    const [transactionData, setTransactionData] = useState([]);
    const [totalCredits, setTotalCredits] = useState(0);
    const [totalDebits, setTotalDebits] = useState(0);
    const [currency, setCurrency] = useState({});
    const [user, setUser] = useLocalStorage("user", null);
    const [listError, sendListRequest] = useAxios(setData);

    function setData(data) {
        var selectedCurrency = data.data.currencies.filter(currency => parseInt(currency.currencyID) === parseInt(partyCurrencyID))[0];
        setCurrency(selectedCurrency);
        setTransactionData(data.data.results);
        setTotalCredits(data.data.totalCredits[selectedCurrency.currencyCode]);
        setTotalDebits(data.data.totalDebits[selectedCurrency.currencyCode]);
    }

    function fetchListData() {
        sendListRequest({
            url: `/accountHead/allAccountHeadsBalance?isAddBuySell=true&partyCurrencyID=${partyCurrencyID}&reportType=party&date=${date}&dateType=${dateType}`,
            method: 'GET',
            body: {},
            headers: { Accept: 'application/json', Authorization: 'Bearer ' + user.access_token }
        })
    }

    useEffect(() => {
        fetchListData();
    }, []);

    return <BalanceReport transactionData={transactionData} totalDebits={totalDebits} date={date} currency={currency} totalCredits={totalCredits} reportName="currency_ledger_report" />
}

export default PartiesCurrencyLedgerBalanceReport