import { useEffect, useState, forwardRef } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useAxios from '../hooks/useAxios';
import useToast from '../hooks/useToast';
import Toast from '../components/Toast';
import { useAuth } from '../hooks/useAuth';
import { useNavigate } from 'react-router-dom';

const theme = createTheme();

export default function Login() {
    const initialValues = {email: "", password: ""};
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);

    const { open, setOpen, responseType, setResponseType, responseMessage, setResponseMessage, handleClose } = useToast();

    const [error, sendLoginRequest] = useAxios(validateLogin);

    const auth = useAuth();
    const navigate = useNavigate();

    const handleChange = (event) => {
      const { name, value } = event.target;
      setFormValues({...formValues, [name] : value});
    }

    const validate = (values) => {
      const errors = {};
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

      if (!values.email.length) {
        errors.email = "Email is required";
      } else if (!regex.test(values.email)) {
        errors.email = "Invalid Email Address";
      }
      if (!values.password.length) {
        errors.password = "Password is required";
      }
      return errors;
    }

    
    const handleSubmit = (event) => {
      event.preventDefault();
      setFormErrors(validate(formValues));
      setIsSubmit(true);
    };

    function validateLogin(data) {
      if (data.status === 200) {
        setResponseType('success');
        setResponseMessage(data.data.message);
        auth.login(data.data);
        navigate('/', { replace: true });
      } else {
        setResponseType('error');
        setResponseMessage(data.data.message);
      }
      setOpen(true);
    }

    useEffect(() => {
      if (Object.keys(formErrors).length === 0 && isSubmit) {
        sendLoginRequest({
          url: '/auth/login',
          method: 'POST',
          body: {
            email: formValues.email,
            password: formValues.password
          },
          headers: { Accept: 'application/json' }
        });
      }
    },[formErrors]);

    

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          
          <Toast open={open} handleClose={handleClose} responseType={responseType} responseMessage={responseMessage} />
          
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              error={Object.keys(formErrors).length > 0 && formErrors.hasOwnProperty('email')}
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={formValues.email}
              onChange={handleChange}
              helperText={formErrors.email}
            />
            <TextField
              error={Object.keys(formErrors).length > 0 && formErrors.hasOwnProperty('password')}
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={formValues.password}
              onChange={handleChange}
              helperText={formErrors.password}
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
