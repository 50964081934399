import { useState } from 'react';

const useToast = () => {
    const [responseType, setResponseType] = useState('success');
    const [responseMessage, setResponseMessage] = useState('');
    const [open, setOpen] = useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
    }


    return { open, setOpen, responseType, setResponseType, responseMessage, setResponseMessage, handleClose };
}

export default useToast;