import { useEffect, useState } from 'react'
import useAxios from '../hooks/useAxios';
import useLocalStorage from '../hooks/useLocalStorage';

const Backup = () => {
    const [backupLink, setBackupLink] = useState('');
    const [listError, sendListRequest] = useAxios(setData);
    const [user, setUser] = useLocalStorage("user", null);

    function setData(data) {
        setBackupLink(data.data);
    }

    useEffect(() => {
        sendListRequest({
            url: '/auth/db-backup',
            method: 'GET',
            body: {},
            headers: { Accept: 'application/json', Authorization: 'Bearer ' + user.access_token }
        })
    },[]);

    return (
        <div>
            <h1 style={{ textAlign: 'center'}}>Creating Backup... Please Wait...</h1>
            <h3 style={{ textAlign: 'center'}}>Backup Link will show below...</h3>
            <div style={{textAlign: 'center'}}><a href={backupLink}>{backupLink.split('/')[5]}</a></div>
        </div>
    )
}

export default Backup
