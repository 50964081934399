import { useSearchParams } from 'react-router-dom';
import LedgerReport from '../../components/LedgerReport';

const PartyLedgerReport = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const partyID = searchParams.get('partyID');

    return <LedgerReport url={`/party/${partyID}`} reportName="party_ledger" />
}

export default PartyLedgerReport