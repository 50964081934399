import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useEffect } from 'react';
import { TablePagination } from '@mui/base';
import useAxios from '../hooks/useAxios';
import useLocalStorage from '../hooks/useLocalStorage';

const PKRLedgerReport = (props) => {
    const { url, reportName, startDate, endDate } = props;

    var colspanLength = 3;
    const currencyID = 1;
    
    const [currencies, setCurrencies] = useState([]);
    const [party, setParty] = useState({});
    const [bankAccount, setBankAccount] = useState({});
    const [currencyTotal, setCurrencyTotal] = useState({});
    const [prevBalance, setPrevBalance] = useState({});
    const [transactionData, setTransactionData] = useState([]);
    const [user, setUser] = useLocalStorage("user", null);
    const [listError, sendListRequest] = useAxios(setData);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(-1);
    const [runningTotal, setRunningTotal] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isRunningTotalLoaded, setIsRunningTotalLoaded] = useState(true);
    const [aryIndex, setAryIndex] = useState([]);

    const { t } = useTranslation();

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - transactionData.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        resetRunningTotal(transactionData, newPage, rowsPerPage);
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        resetRunningTotal(transactionData, 0, event.target.value);
    };

    var myRunningBalance = [];
    var runningBalanceIndex = -1;

    function setData(data) {
        if (!data) return;
        console.log(data.data);
        if (data.data.prevBalance) {
            setPrevBalance(data.data.prevBalance);
        }
        setCurrencyTotal(data.data.totals);
        setCurrencies(data.data.currencies);
        setTransactionData(data.data.ledger);
        resetRunningTotal(data.data.ledger, 0, rowsPerPage);
        setIsLoading(false);
    }

    function resetRunningTotal(transactions, page, rowsPerPage) {
        var thisRunningTotal = {};
        var thisAryIndex = [];
        var thisCurrencyCode = "PKR";
        thisRunningTotal[thisCurrencyCode] = {};
        thisRunningTotal[thisCurrencyCode].debit = 0;
        thisRunningTotal[thisCurrencyCode].credit = 0;
        thisRunningTotal[thisCurrencyCode].balance = 0;
        thisRunningTotal[thisCurrencyCode].bgColor = 'white';
        thisRunningTotal[thisCurrencyCode].runningBalance = [];

        (rowsPerPage > 0 ? transactions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : transactions).map((transaction, index) => {
            thisAryIndex.push(index);
            
            var thisCurrencyCode = "PKR";
            if (!thisRunningTotal.hasOwnProperty(thisCurrencyCode)) {
                thisRunningTotal[thisCurrencyCode] = {};
                thisRunningTotal[thisCurrencyCode].debit = 0;
                thisRunningTotal[thisCurrencyCode].credit = 0;
                thisRunningTotal[thisCurrencyCode].balance = 0;
                thisRunningTotal[thisCurrencyCode].bgColor = 'white';
                thisRunningTotal[thisCurrencyCode].runningBalance = [];
            }
            thisRunningTotal[thisCurrencyCode].debit += Math.abs(transaction.currencyAmount[thisCurrencyCode].debit);
            thisRunningTotal[thisCurrencyCode].credit += Math.abs(transaction.currencyAmount[thisCurrencyCode].credit);
            thisRunningTotal[thisCurrencyCode].balance += Math.abs(transaction.currencyAmount[thisCurrencyCode].credit) - Math.abs(transaction.currencyAmount[thisCurrencyCode].debit);
            thisRunningTotal[thisCurrencyCode].runningBalance[index] = { 'amount': thisRunningTotal[thisCurrencyCode].balance, 'color': thisRunningTotal[thisCurrencyCode].balance >= 0 ? 'green' : 'red', };
            thisRunningTotal[thisCurrencyCode].bgColor = thisRunningTotal[thisCurrencyCode].balance >= 0 ? '#A3F7A4' : '#FFADA5';
        });

        if (Object.keys(prevBalance).length) {
            var thisCurrencyCode = "PKR";
            thisRunningTotal[thisCurrencyCode].debit += Math.abs(prevBalance[thisCurrencyCode].debit);
            thisRunningTotal[thisCurrencyCode].credit += Math.abs(prevBalance[thisCurrencyCode].credit);
            thisRunningTotal[thisCurrencyCode].balance += Math.abs(prevBalance[thisCurrencyCode].credit) - Math.abs(prevBalance[thisCurrencyCode].debit);
            thisRunningTotal[thisCurrencyCode].runningBalance[-1] = { 'amount': thisRunningTotal[thisCurrencyCode].balance, 'color': thisRunningTotal[thisCurrencyCode].balance >= 0 ? 'green' : 'red', };
            thisRunningTotal[thisCurrencyCode].bgColor = thisRunningTotal[thisCurrencyCode].balance >= 0 ? '#A3F7A4' : '#FFADA5';
        }

        console.log({ runningTotal });

        setAryIndex(thisAryIndex);
        setRunningTotal(prevState => ({ ...prevState, ...thisRunningTotal }));
        setIsRunningTotalLoaded(false);
    }

    const handlePrintPDF = () => {
        // const doc = new jsPDF();
        // autoTable(doc, { html: '#daily-cash-report' })
        // doc.save('dailyCashBook.pdf');
        window.print();
    }

    function fetchListData(url) {
        sendListRequest({
            url: url,
            method: 'GET',
            body: {},
            headers: { Accept: 'application/json', Authorization: 'Bearer ' + user.access_token }
        })
    }

    useEffect(() => {
        setIsLoading(true);
        fetchListData(url);
    }, []);

    useEffect(() => {
        if (!isRunningTotalLoaded) {
            resetRunningTotal(transactionData, page, rowsPerPage);
            setIsRunningTotalLoaded(true);
        }
    }, [isRunningTotalLoaded]);

    return (
        <div className="report">
            <Button type="button" onClick={handlePrintPDF}>Print</Button>
            <h4 style={{ textAlign: 'center' }}>{t(reportName)} : {startDate} to {endDate}</h4>
            {
                isLoading ? (
                    <h1 style={{ textAlign: 'center' }}>Loading...Please Wait..</h1>
                ) : (
                    <table cellPadding="5" id="daily-cash-report" style={{ width: '100%', borderCollapse: 'collapse' }} border="1">
                        <thead>
                            <tr>
                                <th>{t('#')}</th>
                                <th>{t('date')}</th>
                                <th>{t('description')}</th>
                                <th>{t('Amount')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(rowsPerPage > 0 ? transactionData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : transactionData
                            ).map((transaction, index) => {
                                runningBalanceIndex++;
                                return (
                                    <tr key={transaction.id}>
                                        <td>{runningBalanceIndex + 1} </td>
                                        <td>{transaction.transactionDate}</td>
                                        <td>{transaction.description}</td>
                                        {
                                            currencies?.map((currency) => {
                                                if (currencyID != undefined && currencyID != currency.currencyID) return;
                                                var amountColor = 'black';
                                                if (transaction.description === 'Previous Balance') {
                                                    var amount = transaction.currencyAmount[currency.currencyCode];
                                                    amountColor = amount < 0 ? 'red' : 'green';
                                                    var transAmount = new Intl.NumberFormat('en-IR').format(amount.toString().replaceAll(',', ''));
                                                    return (
                                                        <td align="right" key={currency.currencyID} style={{ color: amountColor, direction: 'ltr' }}>
                                                            {transAmount}
                                                        </td>
                                                    )
                                                } else {
                                                    if (myRunningBalance[index] === undefined) {
                                                        myRunningBalance[index] = {};
                                                        myRunningBalance[index][currency.currencyCode] = 0;
                                                    }


                                                    if (index === 0) {
                                                        myRunningBalance[index][currency.currencyCode] = runningTotal[currency.currencyCode]?.runningBalance[index]?.amount || 0;
                                                    } else {
                                                        console.log();
                                                        var prevAmountDefined = runningTotal[currency.currencyCode]?.runningBalance[index]?.amount;
                                                        var prevAmount = prevAmountDefined || 0;
                                                        myRunningBalance[index][currency.currencyCode] = (prevAmount === 0 && prevAmountDefined !== 0) ? myRunningBalance[aryIndex[runningBalanceIndex - 1]][currency.currencyCode] : prevAmount;
                                                        console.log({ myRunningBalance, prevAmount });
                                                    }

                                                    var debitAmount = new Intl.NumberFormat('en-IR').format(transaction.currencyAmount[currency.currencyCode].debit.toString().replaceAll(',', ''));
                                                    var creditAmount = new Intl.NumberFormat('en-IR').format(transaction.currencyAmount[currency.currencyCode].credit.toString().replaceAll(',', ''));
                                                    var balanceAmount = 0;
                                                    // var balanceAmount = new Intl.NumberFormat('en-IR').format(transaction.currencyAmount[currency.currencyCode].balance.toString().replaceAll(',', ''));
                                                    // if (runningTotal.hasOwnProperty(currency.currencyCode) && runningTotal[currency.currencyCode].runningBalance.length && runningTotal[currency.currencyCode].runningBalance[index] !== undefined) {
                                                    //     // console.log(index, runningTotal[currency.currencyCode].runningBalance,runningTotal);
                                                    //     balanceAmount = new Intl.NumberFormat('en-IR').format(runningTotal[currency.currencyCode].runningBalance[index].amount.toString().replaceAll(',', ''));
                                                    //     amountColor = runningTotal[currency.currencyCode].runningBalance[index].color;
                                                    // }
                                                    balanceAmount = new Intl.NumberFormat('en-IR').format(myRunningBalance[index][currency.currencyCode].toString().replaceAll(',', ''));
                                                    amountColor = myRunningBalance[index][currency.currencyCode] > 0 ? 'green' : (myRunningBalance[index][currency.currencyCode] === 0 ? 'black' : 'red');
                                                    console.log({ runningTotal, myRunningBalance, index });

                                                    return (
                                                        <>
                                                            <td align="right" key={'debit_' + transaction.transactionID + currency.currencyID} style={{ direction: 'ltr' }}>
                                                                {debitAmount}
                                                            </td>
                                                        </>
                                                    )
                                                }

                                            })
                                        }
                                    </tr>
                                )
                            })
                            }
                            {
                                !transactionData.length && <tr><td colSpan={colspanLength}>No Record Found</td></tr>
                            }
                        </tbody>
                        <tfoot>
                            <tr key={'sub_' + page} style={{ fontWeight: 'bold' }}>
                                <td colSpan={colspanLength} align="right">{t('sub_total')}</td>
                                {
                                    Object.keys(runningTotal).length > 0 && currencies?.map((currency) => {
                                        if (currencyID != undefined && currencyID != currency.currencyID) return;
                                        var totalColor = 'white';
                                        var totalDebit = 0;
                                        var totalCredit = 0;
                                        var totalBalance = 0;
                                        if (runningTotal.hasOwnProperty(currency.currencyCode)) {
                                            totalDebit = new Intl.NumberFormat('en-IR').format(runningTotal[currency.currencyCode].debit.toString().replaceAll(',', ''));
                                            totalCredit = new Intl.NumberFormat('en-IR').format(runningTotal[currency.currencyCode].credit.toString().replaceAll(',', ''));
                                            totalBalance = new Intl.NumberFormat('en-IR').format(runningTotal[currency.currencyCode].balance.toString().replaceAll(',', ''));
                                            totalColor = runningTotal[currency.currencyCode].bgColor;
                                        } else {
                                            totalColor = totalBalance > 0 ? '#A3F7A4' : (totalBalance == 0 ? 'white' : '#FFADA5');
                                        }

                                        return (
                                            <>
                                                <td align="right" key={'pageDebit_' + currency.currencyID + page}>{totalDebit}</td>
                                            </>
                                        )
                                    })
                                }
                            </tr>
                            <tr key={'pagination' + page}>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                    colSpan={(currencies.length * 3) + colspanLength}
                                    count={transactionData.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    slotProps={{
                                        select: {
                                            'aria-label': 'rows per page',
                                        },
                                        actions: {
                                            showFirstButton: true,
                                            showLastButton: true,
                                        },
                                    }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </tr>
                        </tfoot>
                    </table>
                )
            }
        </div>
    )
}

export default PKRLedgerReport